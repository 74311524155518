const cmsConfig = {
  vfoMultiSearchInputHint: ["Ask me a question", "How can I help?"],
  vfoHeadline: "<strong>Hana </strong> - Digital Assistant",
  vfoBotRestartChatLabel: "Start again",
  vfoSplashScreenHeadline:
    "Kia ora. I'm your <strong>One Assistant</strong>",
  vfoWebServiceErrors: [
    {
      id: 1575614769750,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "restart",
      vfoWebServiceErrorCode: 500,
      name: "Restart_Conversation",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "restart",
      vfoErrorMessageText: "Reset <time> - Let's start again",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
    {
      id: 1542069189547,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "inactivity",
      vfoWebServiceErrorCode: 500,
      name: "User_Inactivity",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "inactivity",
      vfoErrorMessageText:
        "Chat closed due to inactivity. Thank you for chatting with me.",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
    {
      id: 1542069341545,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "default",
      vfoWebServiceErrorCode: 500,
      name: "Default_BOT_ERROR",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "default",
      vfoErrorMessageText: "Thank you for chatting with me",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
  ],
  urgentInfo: "",
  welcomeMessages: [
    "Kia ora, I'm your One Assistant.",
    "You've been given early access to our new artificial intelligence (AI) powered customer service.",
    "Whilst using the app, you'll be helped by an AI assistant. If your query is more complex, I'll connect you to one of the team.",
    "Your Privacy is our priority. One New Zealand Group Limited is committed to protecting your personal information.  You can view our <a href='https://one.nz/legal/policy/privacy/'>Privacy Policy</a> at any time to understand how we collect, use and protect your information when you use our app.",
  ],
};

export default cmsConfig;
